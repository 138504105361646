<template>
  <div class="m-3">
    <div v-if="isLoading" class="loading loading-big" style="margin-top: 25%"></div>
    <div v-else class="m-0 p-0">
    <div v-if="this.id" class="d-flex p-3 header-wrapper">
      <p class="my-3 mx-3">
        <label class="mr-3">User Name:</label>
        <span class="text-black">{{ name }}</span>
      </p>
    </div>
    <div class="my-5 body-wrapper">
      <div class="d-flex col-12 p-0">
        <label class="col-4 p-0 mr-5">
          Name
        </label>
        <label v-if="!isDriverDetail" class="col-4 p-0">
          Email
        </label>
        <label v-else class="col-4 p-0">
          IC/Passport
        </label>
      </div>
      <div class="d-flex col-12 p-0 my-3">
        <div class="col-4 p-0 mr-5">
          <div v-if="canEdit" class="custom-icon small-icon pencil-icon icon-float-right"></div>
          <input 
            v-model="name" 
            type="text" 
            class="form-control" 
            :disabled="!canEdit" 
            @keydown="errorField.name = null"
          >
          <div v-if="errorField.name" class="text-error d-flex">{{ errorField.name }}</div>
        </div>
        <div class="col-4 p-0">
          <div v-if="canEdit" class="custom-icon small-icon pencil-icon icon-float-right"></div>
          <input 
            v-model="emailOrICPassport" 
            type="text" 
            class="form-control" 
            :disabled="!canEdit"
            @keydown="errorField.emailOrICPassport = null"
          >
          <div v-if="errorField.emailOrICPassport" class="text-error d-flex">{{ errorField.emailOrICPassport }}</div>
        </div>
      </div>
      <div class="d-flex col-12 p-0 mt-5 w-100">
        <label v-if="!isDriverDetail" class="col-4 p-0 mr-5">
          User Role
        </label>
        <label class="col-4 p-0">
          User Status
        </label>
      </div>
      <div class="d-flex col-12 p-0 my-3 w-100">
        <div v-if="!isDriverDetail" class="col-4 p-0 mr-5">
          <b-form-select 
            id="user-role" 
            class="p-4" 
            v-model="role" 
            :options="availableUserRoles"
            @input="errorField.role = null"
          ></b-form-select>
          <div v-if="errorField.role" class="text-error d-flex">{{ errorField.role }}</div>
        </div>
        <div class="col-4 p-0">
          <b-form-select 
            id="user-status" 
            class="p-4" 
            v-model="status" 
            :style="getStatusColor(status)"
            :options="availableUserStatus"
            @input="errorField.status = null"
          ></b-form-select>
          <div v-if="errorField.status" class="text-error d-flex">{{ errorField.status }}</div>
        </div>
      </div>
    </div>
    <div style="padding-bottom: 15rem"></div>
    <div class="page-footer">
      <div class="row justify-content-center">
        <router-link :to="'/'" @click.native="handleReturn()" class="btn btn-back justify-content-center mr-5 px-4">Back to User Listing</router-link>
        <b-button v-if="canEdit" @click="handleButton()" class="px-5">{{ id ? 'Update' : 'Add'}}</b-button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { EventBus } from "../../utils/EventBus";
import { emailValidate } from "../../utils/helpers"
import * as userActions from "../../store/modules/user/actions"
import * as uiActions from "../../store/modules/_shared/ui/actions"

export default {
  name: "UserManagementDetail",
  data() {
    return {
      isLoading: false,
      isDriverDetail: false,
      currentUser: this.$store.getters.getUser,
      canEdit: false,
      id: null,
      name: null,
      emailOrICPassport: null,
      role: null,
      status: null,
      availableUserRoles: [],
      availableUserStatus: [],
      errorField: {},
    }
  },
  watch: {
  },
  mounted() {
    this.id = this.$route.params.id
    if (this.id) this.getUserDetailAndInitData(this.id)
    this.canEdit = this.currentUser.isAdmin

    this.availableUserRoles = this.$store.getters.getAvailableUserRoles
    this.availableUserStatus = this.$store.getters.getAvailableUserStatus

    this.initUserRoles()
  },
  computed: {
  },
  methods: {
    getStatusColor(value) {
      if (value === 'ACTIVE') return {'color': '#22D60E'}
      else return {'color': ''}
    },
    initUserRoles() {
      this.availableUserRoles = this.availableUserRoles.filter(el => {
        return el.value !== 'ROLE_DRIVER'
      })
      if (!this.id) this.availableUserRoles = this.availableUserRoles.filter(el => {
        return (el.value == 'ROLE_MANAGER' || el.value == 'ROLE_LOGISTICS_OFFICER')
      })
    },
    getUserDetailAndInitData(id) {
      this.isLoading = true
      this.$store.dispatch(userActions.USER_PROFILE_REQUEST, {userId: id}).then(res => {
        if (res.status === "ok" && res.response) {
          this.parseDetail(res.response);
          EventBus.$emit('passSubtitles', [this.name])
        }
      }).finally(() => {this.isLoading = false;})
    },
    parseDetail(userDetail) {
      if (userDetail.role === 'ROLE_DRIVER') this.isDriverDetail = true
      this.name = userDetail.name
      this.status = userDetail.status
      this.emailOrICPassport = userDetail.loginId
      this.role = userDetail.role
      if (this.currentUser.isManager) this.canEdit = this.isDriverDetail ? true : false
    },
    handleButton() {
      if (this.id) this.handleUpdate()
      else this.handleAdd()
    },
    handleUpdate() {
      this.isLoading = true
      if (this.isFieldInvalid()) {
        this.isLoading = false
        this.$store.dispatch(uiActions.NOTIFICATION_ERROR, {
          message: "There is/are invalid field(s)."
        })
        return
      }
      let postData = {
        userId: this.id,
        name: this.name,
        loginId: this.emailOrICPassport,
        role: this.role,
        status: this.status,
      }
      this.$store.dispatch(userActions.USER_PROFILE_POST_REQUEST, postData).then(res => {
        if (res.status != 'ok') throw(res)
        this.$store.dispatch(uiActions.NOTIFICATION_SUCCESS, {
          message: `Successfully updated user with name = ${res.response.name}`
        })
      }).catch(err => {
        this.$store.dispatch(uiActions.NOTIFICATION_ERROR, {
          message: err.errorMessage
        })
      }).finally(() => {
        this.isLoading = false
        this.handleReturn()
      })
    },
    handleAdd() {
      this.isLoading = true
      if (this.isFieldInvalid()) {
        this.isLoading = false
        this.$store.dispatch(uiActions.NOTIFICATION_ERROR, {
          message: "There is/are invalid field(s)."
        })
        return
      }
      let postData = {
        name: this.name,
        loginId: this.emailOrICPassport,
        role: this.role,
        status: this.status,
      }
      this.$store.dispatch(userActions.USER_PROFILE_ADD_REQUEST, postData).then(res => {
        if (res.status != 'ok') throw(res)
          this.$store.dispatch(uiActions.NOTIFICATION_SUCCESS, {
            message: `Successfully added user with name = ${res.response.name}`
          })
        }).catch(err => {
          this.$store.dispatch(uiActions.NOTIFICATION_ERROR, {
            message: err.errorMessage
          })
        }).finally(() => {
          this.isLoading = false
          this.handleReturn()
        })
    },
    handleReturn() {
      this.$router.push({path: `/user-management/${this.isDriverDetail ? 1 : 0}`})
    },
    isFieldInvalid() {
      let hasError = false
      if (!this.emailOrICPassport || this.emailOrICPassport == "") {
        this.errorField.emailOrICPassport = "This field can't be empty!"
        hasError = true
      } else if (this.role != "ROLE_DRIVER" && !emailValidate(this.emailOrICPassport)) {
        this.errorField.emailOrICPassport = "Invalid email!"
        hasError = true
      }
      if (!this.name || this.name == "") {
        this.errorField.name = "This field can't be empty!"
        hasError = true
      }
      if (!this.role || this.role == "") {
        this.errorField.role = "Please choose one role"
        hasError = true
      }
      if (!this.status || this.status == "") {
        this.errorField.status = "Please choose either active or inactive for this user"
        hasError = true
      }

      return hasError
    }
  }
}
</script>

<style lang="scss" scoped>
.header-wrapper {
  background-color: white;
  text-align: center;
  border-radius: 10px;
  p {
    height: 1rem;
    line-height: 1rem;
  }
}

label {
  color: #545557;
}

.custom-select {
  height: 100%;
  border-radius: 10px;
  border: none;
}

.btn-back {
  color: #545557;
  border : 1px solid #C2C5D3;
}
.text-error {
  color: red
}
</style>